import React, { useState } from 'react';
import Icon from '../Icons/Icons';
import { Button } from 'react-bootstrap';
import { GetPaymentTypeLastFourDigits } from '../../../api/user';
import Skeleton from "react-loading-skeleton";

interface ITogglePayType {
    onToggle?: Function;
    optionalClass?: string;
    valutid?: string;
}

function TogglePayType({
    onToggle,
    optionalClass = "",
    valutid
}: ITogglePayType) {
    const [showType, setShowType] = useState(false);
    const [load, setload] = useState(false);
    const [nmiData, setnmiData] = useState('');
    const toggleView = (e: any) => {
        setShowType(!showType);
        if (!showType && valutid != null) {
            let storedData = sessionStorage.getItem(valutid)
            if (storedData) {
                setnmiData(storedData);
            } else {
                getlastfourdigit(valutid);
            }
        } else {
            setnmiData('No data available to view')
        }
    }


    const getlastfourdigit = async (id: any) => {
        setload(true);
        GetPaymentTypeLastFourDigits(id).then((res: any) => {
            if (res.status === 200) {
                let nmiNumber = res?.data?.customerVault?.ccNumber ? res?.data?.customerVault?.ccNumber : res?.data?.customerVault?.checkABA;
                nmiNumber = nmiNumber.substring(nmiNumber.length - 4, nmiNumber.length)
                sessionStorage.setItem(res?.data?.paymentGatewayCustomerVaultId, nmiNumber);
                setnmiData(nmiNumber)
            }
        }).catch((err: any) => {
            console.log(err);
            setnmiData('No data available to view')
            setload(true);
        }).finally(() => {
            setload(false);
        })
    }

    return (
        <>

            <Button variant="link" onClick={toggleView} className={`text-dark shadow-none ${optionalClass}`} size="sm" title={showType ? "Hide Payment Type Information" : "View Payment Type Information"} aria-label="Payment Type Information">
                <Icon size={20} name={showType ? "eye-off" : "eye-on"} />
            </Button>
            {load && <Skeleton width={"100%"} height={20} />}
            {showType && !load ? <>
                <br></br>
                <strong>
                    {nmiData.includes('No data available to view') ? nmiData : `****${nmiData}`}
                </strong>
            </>
                : null
            }
        </>
    );
}

export default TogglePayType