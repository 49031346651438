// Hooks
import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../app/hooks";

// Components
import { Form, Button } from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";
import { ConfirmModal } from "../../components/Common/Modal";

// Api
import {
  getUserPaymentMethods,
  updateUserPaymentMethodNickName
} from "../../api/userPaymentMethods";

// Store
import { initUserPaymentMethod } from "../../features/payment/paymentSlice";

// Util
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { replaceSpace, userNameRegEx, replaceSpecialCharsAndNumbers } from "../../util/regexUtil";
import { $toast , toastMessage} from "../../util/toastUtil";

type PaymentMethodFormType = {
  Method_Nickname: string;
  Payment_Type: string;
  Ach_Payment_Type: string;
  ACH_Type: string;
};

interface IUserPaymentMethodItem {
  id: string;
  Payment_Type: string;
  Method_Nickname: string;
  AccountHolderType: string;
  AccountType: string;
}

interface IEditEditPaymentMethodFormProps {
  selectedPaymentMethod: IUserPaymentMethodItem;
  setLoading: (loading: boolean) => void;
  setIsEditingPaymentMethod: (isEditing: boolean) => void;
  setSelectedPaymentMethod: (
    selectedMethod: IUserPaymentMethodItem | null
  ) => void;
  isEditingPaymentMethod:boolean;
  Ach_Payment_Type: string;
  ACH_Type: string;
}

const EditPaymentMethodForm: React.FC<any> = (
  props: IEditEditPaymentMethodFormProps
) => {
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    Method_Nickname: Yup.string()
      .required("Payment Nickname is required")
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,getValues
  } = useForm<PaymentMethodFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      Method_Nickname:'',
      Payment_Type:'',
      Ach_Payment_Type: '',
      ACH_Type: ''
    }
  });

  useEffect(()=>{
    setValue('Method_Nickname', props?.selectedPaymentMethod?.Method_Nickname)
    setValue('Payment_Type', props?.selectedPaymentMethod?.Payment_Type)
    setValue('Ach_Payment_Type', (props?.selectedPaymentMethod as any)?.paymentMethodAttributes?.AccountType)
    setValue('ACH_Type', (props?.selectedPaymentMethod as any)?.paymentMethodAttributes?.AccountHolderType)
  },[props.isEditingPaymentMethod])

  const save =(formValue: PaymentMethodFormType)=>{
    disposeModal();
    props.setLoading(true)
    updateUserPaymentMethodNickName({
      userPaymentMethodId: props.selectedPaymentMethod?.id,
      userPaymentMethodNickName: formValue.Method_Nickname.trim()
    })
    .then((res)=>{
      $toast.success({
        title:"Success",
        body: toastMessage.savedUserPaymentMethodSuccessful
      })

        // re-load data:
        getUserPaymentMethods()
        .then((userMethodRep) =>{
          dispatch(initUserPaymentMethod(userMethodRep.data))
          props.setLoading(false)
        })
        .catch((getUserMethodsError) =>{
          props.setLoading(false)
          $toast.danger({
            title:'Failed',
            body: toastMessage.loadUserPaymentMethodsForFailed
          })
        })
    })
    .catch((err)=>{
      props.setLoading(false)
      $toast.danger({
        title:"Method Not Saved",
        body:err.response?.data ?? toastMessage.savedUserPaymentMethodFailed
      })
    })
  }


  const disposeModal = () => {
    props.setIsEditingPaymentMethod(false);
    props.setSelectedPaymentMethod(null);
  };

  return (
    <ConfirmModal
      show={props.isEditingPaymentMethod}
      title="Edit Payment Method"
      size="lg"
      contentClassName="profile-edit-payment-method-form"
      description="Edit and save your payment method."
      showFooter={false}
      onCancel={()=>{
        disposeModal()
      }}
    >
      <Form>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="methodNickname">Payment Nickname</Form.Label>
          <Controller
            name="Method_Nickname"
            control={control}
            render={({ field }) => (
              <Form.Control
                type="text"
                id="methodNickname"
                placeholder="Method NickName"
                value={field.value || ''}
                maxLength={25}
                className={errors.Method_Nickname ? "form-field-error" : ""}
                onChange={(e) => {
                  const _value = e.target.value;
                  field.onChange(_value);
                }}
              />
            )}
          />
          {/* {errors?.Method_Nickname && errors?.Method_Nickname.type && (
            <span className="form-field-error-msg">
              {errors?.Method_Nickname?.message?.toString()}
            </span>
          )} */}
        </Form.Group>
        
        <Form.Group className="mb-3">
          <Form.Label htmlFor="Payment_Type">Payment Type</Form.Label>
          <Controller
            name="Payment_Type"
            control={control}
            render={({ field }) => (
              <Form.Control
                type="text"
                value={field.value || ''}
                disabled
              />
            )}
          />
        </Form.Group>

        {(props.selectedPaymentMethod as any)?.paymentMethodAttributes != null ?
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="Ach_Payment_Type">Type</Form.Label>
              <Controller
                name="Ach_Payment_Type"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    value={field.value || ''}
                    disabled
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="Payment_Type">ACH Type</Form.Label>
              <Controller
                name="ACH_Type"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    value={field.value || ''}
                    disabled
                  />
                )}
              />
            </Form.Group>
          </> : null
        }

        <div className="text-end">
          <Button
            variant="outline-primary"
            size="sm"
            className="mb-3"
            onClick={disposeModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="mb-3 ms-3"
            onClick={
              handleSubmit(
                (validForm: PaymentMethodFormType)=>{
                  save(validForm)
                },
                (err: any) => {
                  console.log('err: ', err)
                }
              )
            }
          >
            <Icon name="check-double" optionalClass="me-2" size={20}></Icon>
            Save
          </Button>
        </div>
      </Form>
    </ConfirmModal>
  );
};
export default EditPaymentMethodForm;
