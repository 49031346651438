import React, {
  useEffect,
  useImperativeHandle,
  forwardRef
} from "react";

// Components
import { Button } from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";

import { OverlayTrigger, Popover } from "react-bootstrap";

import CVVHelpImage from "../../assets/Images/CVV-Code.png";
import AccountHelpImage from "../../assets/Images/Routing-Account-Number.png";

import { addEventrForCollectJsField } from '../../util/collectJsValid'
import { $toast } from "../../util/toastUtil";
import { setisVaildCvv } from "../../features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";

const cvvHelp = (
  <Popover id="popover-cvv">
    <Popover.Body>
      <img src={CVVHelpImage} />
    </Popover.Body>
  </Popover>
);
const accountHelp = (
  <Popover id="popover-account">
    <Popover.Body>
      <img src={AccountHelpImage} width='242' height='102' />
    </Popover.Body>
  </Popover>
);

// Types
declare global {
  interface Window {
    CollectJS?: any;
  }
}

interface IPayment {
  finishSubmit: (token: any) => void;
  errorCallback?: (error: string) => void;
  cancel: () => void;
  setLoading: (loading: boolean) => void;
  hideFooter?: boolean | undefined;
  showAccountName?: boolean | undefined;
  showAccountNumber?: boolean | undefined;
  showRoutingNumber?: boolean | undefined;
  showCcNumber?: boolean | undefined;
  showCcexp?: boolean | undefined;
  showCvv?: boolean | undefined;
  accountNameText?: string | undefined;
  accountNumberText?: string | undefined;
  routingNumberText?: string | undefined;
  ccNumberText?: string | undefined;
  ccExpText?: string | undefined;
  cvvText?: string | undefined;
  isCvvRequired?: boolean | undefined;
}

// ({ formProps, getPaymentInfo }: IformProps) {

export const Payment = forwardRef((props: IPayment, ref) => {
  const {
    finishSubmit,
    errorCallback,
    cancel: cancel,
    setLoading,
    hideFooter = false,
    showAccountName,
    showAccountNumber,
    showCcNumber,
    showCcexp,
    showCvv,
    showRoutingNumber,
    accountNameText = "Account Name",
    accountNumberText = "Bank Account Number",
    routingNumberText = "ABA Routing Number",
    ccNumberText = "CC Number",
    ccExpText = "CC Exp",
    cvvText = "CVV Code",
    isCvvRequired
  } = props;

  const dispatch = useAppDispatch();

  const { isVaildCvv } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  useEffect(() => {
    window.CollectJS.configure({
      paymentSelector: "#PayButton",
      theme: "bootstrap",
      variant: "inline",
      styleSniffer: true,
      customCss: {
        "border-style": "none",
        "height": "24px"
      },
      invalidCss: {},
      validCss: {},
      placeholderCss: {},
      focusCss: {},
      timeoutDuration: 30 * 1000,


      /**
       * Which gets called if timeoutDuration has passed since we tried to submit the form
       */
      timeoutCallback: function () {
        if (isVaildCvv !== false) {
          setLoading(false);
          const errorMessage = "The tokenization didn't respond in the expected time. This could be due to an invalid or incomplete field or poor connectivity"
          $toast.danger({
            title: 'Method Not Saved',
            body: errorMessage
          })
          console.error(errorMessage);
          if (errorCallback) {
            errorCallback(errorMessage)
          }
        }
      },

      /**
       * Which gets called once Collect.js has installed the fields onto your page.
       */
      fieldsAvailableCallback: function () {
        console.log("fieldsAvailableCallback: ");
        addEventrForCollectJsField()
      },

      validationCallback: function (fieldName:any, valid:any, message:any) {
        if(window !== undefined){
        checkAllFiledValidation(fieldName, valid, message)
        }
      },

      /**
       * Will execute when the client submits the payment form and payment info has been successfully stored.
       * @param token
       */
      callback: (token: any) => {
        console.log(token);
        finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: "0000 0000 0000 0000",
          title: "Card Number",
          selector: "#ccnumber"
        },
        ccexp: {
          placeholder: "MM / YY",
          title: "Card Expiration",
          selector: "#ccexp"
        },
        cvv: {
          placeholder: "3 or 4 digits",
          title: "CVV Code",
          selector: "#cvv",
          display: (isCvvRequired ? 'show' : 'required')
        },
        checkname: {
          placeholder: "Name On Account",
          title: "Checking account name",
          selector: "#checkname"
        },
        checkaccount: {
          placeholder: "Account Number",
          title: "Checking account number",
          selector: "#checkaccount"
        },
        checkaba: {
          placeholder: "ABA Routing Number",
          title: "ABA Routing Number",
          selector: "#checkaba"
        }
      }
    });
  }, []);

  function checkAllFiledValidation(fieldName: string, valid: any, message: string) {
    if (!valid) {
      let _invalidMessage = ""
      if (message == "Field is empty") {
        switch (fieldName) {
          case "ccnumber":
            _invalidMessage = "Card Number is required"
            break;
          case "ccexp":
            _invalidMessage = "Card Expiration Date is required"
            break;
          case "cvv":
            _invalidMessage = "CVV is required"
            dispatch(setisVaildCvv(false))

            break;
          case "checkname":
            _invalidMessage = "Name on Account is required"
            break;
          case "checkaccount":
            _invalidMessage = "Account Number is required"
            break;
          case "checkaba":
            _invalidMessage = "Routing Number is required"
            break;
          default:
            _invalidMessage = message
            break;
        }
      } else {
        _invalidMessage = message
      }
      let tagName: any = document.querySelector("#" + fieldName);
      if (fieldName === "cvv" || fieldName === "checkaccount" || fieldName === "checkaba") {
        let _errorSpan = tagName.parentNode.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.parentNode.removeChild(_errorSpan)
        }

        tagName.parentNode.classList.add("form-field-error")

        let _spanNode = document.createElement("span")
        let _textNode = document.createTextNode(_invalidMessage)

        _spanNode.appendChild(_textNode)
        _spanNode.className = "form-field-error-msg"

        tagName.parentNode.parentNode.appendChild(_spanNode)

      } else {
        let _errorSpan = tagName.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.removeChild(_errorSpan)
        }

        tagName.classList.add("form-field-error")

        let _spanNode = document.createElement("span")
        let _textNode = document.createTextNode(_invalidMessage)

        _spanNode.appendChild(_textNode)
        _spanNode.className = "form-field-error-msg"

        tagName.parentNode.appendChild(_spanNode)
      }
    } else {
      let tagName: any = document.querySelector("#" + fieldName);
      if (fieldName === "cvv" || fieldName === "checkaccount" || fieldName === "checkaba") {
        if (fieldName === "cvv") {
          dispatch(setisVaildCvv(true))
        }
        tagName.parentNode.classList.remove("form-field-error")

        let _errorSpan = tagName.parentNode.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.parentNode.removeChild(_errorSpan)
        }
      } else {
        tagName.classList.remove("form-field-error")

        let _errorSpan = tagName.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.removeChild(_errorSpan)
        }
      }
    }
  }

  useImperativeHandle(ref, () => ({
    submit,
    clearInputs
  }));

  const submit = async () => {
    // Preventing the page from reloading
    //event.preventDefault();
    setLoading(true);
    console.log("handleSubmit");
    window.CollectJS.startPaymentRequest();
    return;
  };
  const closePaymentRequest = async () => {
    console.log("closePaymentRequest");
    window.CollectJS.closePaymentRequest();
    return;
  };
  const clearInputs = async () => {
    console.log("clearInputs");
    window.CollectJS.clearInputs();
    return;
  };

  return (
    <div>
      {showAccountName && (
        <div className="mt-3">
          <label htmlFor="checkname" className="form-label form-label-important">
            {accountNameText}:
          </label>
          <div id="checkname" className="form-control" />
        </div>
      )}

      {showRoutingNumber && (
        <div className="row">
          <div className="col">
            <div className="mt-3">
              <label htmlFor="checkaba" className="form-label form-label-important">
                {routingNumberText}:
              </label>
              <div className="input-group form-field-radius">
                <div id="checkaba" className="form-control" />
                <span className="input-group-text">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={accountHelp}
                  >
                    <div>
                      <Icon name="question" size={20} />
                    </div>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAccountNumber && (
        <div className="row">
          <div className="col">
            <div className="mt-3 mb-3">
              <label htmlFor="checkaccount" className="form-label form-label-important">
                {accountNumberText}:
              </label>
              <div className="input-group form-field-radius">
                <div id="checkaccount" className="form-control" />
                <span className="input-group-text">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={accountHelp}
                  >
                    <div>
                      <Icon name="question" size={20} />
                    </div>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCcNumber && (
        <div className="mt-3">
          <label htmlFor="ccnumber" className="form-label form-label-important">
            {ccNumberText}:
          </label>
          <div id="ccnumber" className="form-control" />
        </div>
      )}

      {(showCvv || showCcexp) && (
        <div className="row">
          {
            showCvv &&
            (
              <div className="col">
                <div className="mt-3 mb-3">
                  <label htmlFor="cvv" className="form-label form-label-important">
                    {cvvText}:
                  </label>
                  <div className="input-group form-field-radius">
                    <div id="cvv" className="form-control" />
                    <span className="input-group-text">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={cvvHelp}
                      >
                        <div>
                          <Icon name="question" size={20} />
                        </div>
                      </OverlayTrigger>
                    </span>
                  </div>
                </div>
              </div>
            )
          }
          <div className="col">
            {showCcexp && (
              <div className="mt-3 mb-3">
                <label htmlFor="ccexp" className="form-label form-label-important">
                  {ccExpText}:
                </label>
                <div id="ccexp" className="form-control" />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="text-end" style={hideFooter ? { display: "none" } : {}}>
        <Button
          variant="outline-primary"
          size="sm"
          className="mb-3"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          id="PayButton"
          variant="primary"
          size="sm"
          className="mb-3 ms-3"
          type="submit"
          onClick={submit}
        >
          <Icon name="check-double" optionalClass="me-2" size={20}></Icon>
          Save
        </Button>
      </div>
    </div>
  );
});

export default Payment;
