import React, { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import Icon from "../../components/Common/Icons/Icons";
import Select from 'react-select';
//import { billingsAttributes } from "./billingAttributes";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import { getClientBillingAttribute, getGeneralBillingAttributes, getPaymentBillingAttributes } from "../../api/configureClient";
import { LoadingWaitModal } from "../../components/Common/Modal";
import { useNavigate } from "react-router-dom";
import { ToastNotification } from "../../components/Common/Toast";
import AttributesPlaceholder from "./placeholders/attributesPlaceholder";
import Skeleton from "react-loading-skeleton";
import ConfigureattributesPlaceholder from "./placeholders/ConfigureattributesPlaceholder";
import { allowOneDot } from "../../util/formatUtil";

type props = {
  control: any,
  errors: any,
  setValue: any,
  getValues: any
  trigger: any,
  setStep: any
};

const ConfigureAttributes: FC<props> = ({ control, errors, setValue, getValues, trigger, setStep }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const generalBillingAttributes = useAppSelector((state: any) => state.generalBillingAttributes.data, shallowEqual)
  const paymetBillingAttributes = useAppSelector((state: any) => state.paymentBillingAttributes.data, shallowEqual)
  const saveBillingAttributes = useAppSelector((state: any) => state.saveBillingAttributes, shallowEqual)
  const ClientBillingAttribute = useAppSelector((state: any) => state.getClientBilling, shallowEqual)
  const generalBillingAttributesLoader = useAppSelector((state: any) => state.generalBillingAttributes.status, shallowEqual)
  const paymetBillingAttributesLoader = useAppSelector((state: any) => state.generalBillingAttributes.status, shallowEqual)

  const [showSucess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  const clientId = getValues("step1.companyName.value")

  useEffect(() => {
    if(generalBillingAttributes.length == 0) {
      if(clientId) {
        dispatch(getGeneralBillingAttributes(clientId))
      }
    }
    updateGeneralBillingAttribute();
  }, [generalBillingAttributes]);

  useEffect(() => {
    if(paymetBillingAttributes.length == 0) {
      if(clientId) {
        dispatch(getPaymentBillingAttributes(clientId))
      }
    }
    updatePaymentBillingAttribute();
  }, [paymetBillingAttributes]);

  const [billingGeneralAttributesOptions, setGeneralBillingAttributesOptions] = useState();
  const [billingPaymentAttributesOptions, setPaymentBillingAttributesOptions] = useState();

  const { fields: fieldsGeneral, append: appendGeneral, remove: removeGeneral, move: moveGeneral } = useFieldArray({
    control,
    name: "step3.generalAttributes"
  });

  const { fields: fieldsPayment, append: appendPayment, remove: removePayment, move: movePayment } = useFieldArray({
    control,
    name: "step3.paymentAttributes"
  });

  const addNewAttributesForGeneral = (action: string) => {
    if (action === "add") {
      appendGeneral({
        billingAttributes: { value: 0, label: 'Select Attribute' },
        attributeType: "",
        uiLabel: '',
        isCustom: false,
        customAttributes: ""
      });
    } else {
      appendGeneral({
        billingAttributes: { value: 0, label: 'Select Attribute' },
        attributeType: "Money",
        uiLabel: '',
        isCustom: true,
        customAttributes: ""
      });
    }
  };

  const addNewAttributesForPayment = (action: string) => {
     if (action === "add") {
      appendPayment({
        billingAttributes: { value: 0, label: 'Select Attribute' },
        attributeType: "",
        uiLabel: '',
        isCustom: false,
        isEditable: false,
        customAttributes: ""
      });
    } else {
      appendPayment({
        billingAttributes: { value: 0, label: '' },
        attributeType: "Money",
        uiLabel: '',
        isCustom: true,
        isEditable: false,
        customAttributes: ""
      });
    }
  };

  const removeAttributesOfGeneral = (index: number) => () => {
    removeGeneral(index);
    updateGeneralBillingAttribute();
  };

  const removeAttributesOfPayment = (index: number) => () => {
    removePayment(index);
    updatePaymentBillingAttribute();
  };

  useEffect(() => {
    if (fieldsGeneral.length < 1) {
      addNewAttributesForGeneral("add");
    }
    //slist(document.getElementById("sortlistGeneral"), onDragEndGeneral);
  }, [fieldsGeneral.length]);

  useEffect(() => {
    if (fieldsPayment.length < 1) {
      addNewAttributesForPayment("add");
    }
    //slist(document.getElementById("sortlistPayment"), onDragEndPayment);
  }, [fieldsPayment.length]);

  useEffect(()=> {
    if(clientId) {
      dispatch(getClientBillingAttribute(clientId));
      dispatch(getGeneralBillingAttributes(clientId));
      dispatch(getPaymentBillingAttributes(clientId));
    }
  },[])

  useEffect(() => {
     console.log("ClientBillingAttribute", ClientBillingAttribute.data)
     //if(ClientBillingAttribute.data.generalAttributes) {
      setValue("step3.generalAttributes", ClientBillingAttribute.data.generalAttributes);
      updateGeneralBillingAttribute();
     //}
    //if(ClientBillingAttribute.data.paymentAttributes) {
      setValue("step3.paymentAttributes", ClientBillingAttribute.data.paymentAttributes);
      updatePaymentBillingAttribute();
   // }
 }, [ClientBillingAttribute])

  const updateGeneralBillingAttribute = () => {
    const selectedBillingAttributes = getValues("step3.generalAttributes") || [];
    let filtertedBillingAttributes: any = [];
    selectedBillingAttributes.map((billing: any) => {
      if (billing?.billingAttributes && billing?.billingAttributes?.value != "") {
        filtertedBillingAttributes.push(billing.billingAttributes);
      }
    });
    let arrBillingAttrbutes: any = [];
    generalBillingAttributes?.map((billing: any) => {
      let index = filtertedBillingAttributes.findIndex((filterAttr: any) => {
        return billing.attributeName.value === filterAttr.value
      });
      if (index == -1) {
        arrBillingAttrbutes.push(billing.attributeName);
      }
    })
    arrBillingAttrbutes.splice(0, 0, { label: "Select Attribute", value: "" });
    setGeneralBillingAttributesOptions(arrBillingAttrbutes);
  }

  const updatePaymentBillingAttribute = () => {
    const selectedBillingAttributes = getValues("step3.paymentAttributes") || [];
    let filtertedBillingAttributes: any = [];
    selectedBillingAttributes.map((billing: any) => {
      if (billing?.billingAttributes && billing?.billingAttributes?.value != "") {
        filtertedBillingAttributes.push(billing.billingAttributes);
      }
    });
    let arrBillingAttrbutes: any = [];
    paymetBillingAttributes?.map((billing: any) => {
      let index = filtertedBillingAttributes.findIndex((filterAttr: any) => {
        return billing.attributeName.value === filterAttr.value
      });
      if (index == -1) {
        arrBillingAttrbutes.push(billing.attributeName);
      }
    })
    arrBillingAttrbutes.splice(0, 0, { label: "Select Attribute", value: "" });
    setPaymentBillingAttributesOptions(arrBillingAttrbutes);
  }

  const arrTypes = [
    { label: "Select Type", value: "" },
    { label: "String", value: "String" },
    { label: "Date", value: "Date" },
    { label: "Money", value: "Money" },
    { label: "Decimal/Float", value: "Decimal/Float" }
  ];

  const childrenRef = React.useRef() as any;

  const reorderGeneral = (result: any) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    moveGeneral(sourceIndex, destIndex);
  };

  const reorderPayment = (result: any) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    movePayment(sourceIndex, destIndex);
  };

  return (
    <>
      {/* <LoadingWaitModal show={ClientBillingAttribute.status ==="loading" */}
    {/* || generalBillingAttributesLoader === "loading" || paymetBillingAttributesLoader === "loading"} size="lg" hide={() => { }} /> */}
      <div className="w-100" >
        <h2 className="h4">
          Configure Attributes for General Information
        </h2>
        <div className="app-config-attributess">
          <div className="app-config-attributes_row row-header">
            <div className="app-config-attributes_header fixed-column-size-small">Sort</div>
            <div className="app-config-attributes_header">
              Billing Attributes
            </div>
            <div className="app-config-attributes_header">Type</div>
            <div className="app-config-attributes_header">
              UI Display Label
            </div>
            <div className="app-config-attributes_header fixed-column-size-small">
              Delete
            </div>
          </div>
          {ClientBillingAttribute.status === "loading"
            || generalBillingAttributesLoader === "loading" || paymetBillingAttributesLoader === "loading" ?
            <AttributesPlaceholder /> :
            <DragDropContext onDragEnd={reorderGeneral}>
              <Droppable droppableId="parentGeneral">
                {(provided) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps} className="row-data-container">
                    {fieldsGeneral.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="app-config-attributes_row app-config-item">
                            <div
                              className="app-config-attributes_data fixed-column-size-small drag-icon"
                              {...provided.dragHandleProps}><Icon name="drag"></Icon></div>
                            <div className="app-config-attributes_data">

                              {
                                !getValues(`step3.generalAttributes[${index}].isCustom`) &&
                                <>
                                  <Controller
                                    name={`step3.generalAttributes[${index}].billingAttributes`}
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Select options={billingGeneralAttributesOptions}
                                          className={errors?.step3 && (errors?.step3 as any)?.generalAttributes && (errors?.step3 as any)?.generalAttributes[index]?.billingAttributes ? "form-field-error layer-top-drag" : ""}
                                          defaultValue={field.value}
                                          onChange={(billing: any) => {
                                            field.onChange(billing);
                                            const selectedAttribute = generalBillingAttributes.find((billingAttr: any) => {
                                              return billingAttr.attributeName.value == billing.value
                                            })
                                            if (billing.value) {
                                              updateGeneralBillingAttribute();
                                              setValue(`step3.generalAttributes[${index}].attributeType`, selectedAttribute?.attributeType)
                                              trigger(`step3.generalAttributes`)
                                            }
                                          }}
                                        ></Select>
                                      </>
                                    )}
                                  />
                                  {errors?.step3 && (errors?.step3 as any)?.generalAttributes && errors?.step3 && (errors?.step3 as any)?.generalAttributes[index]?.billingAttributes?.type === "unique" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.generalAttributes[index]?.billingAttributes?.message?.toString()}</span>}
                                </>
                              }
                              {
                                getValues(`step3.generalAttributes[${index}].isCustom`) &&
                                <>
                                  <Controller
                                    name={`step3.generalAttributes[${index}].customAttributes`}
                                    control={control}
                                    render={({ field }) => (
                                      <Form.Control
                                        value={field.value}
                                        className={errors?.step3 && (errors?.step3 as any)?.generalAttributes && (errors?.step3 as any)?.generalAttributes[index]?.customAttributes ? "form-field-error layer-top-drag" : "layer-top-drag"}
                                        onChange={(e) => {
                                          field.onChange(e.target.value)
                                          trigger(`step3.generalAttributes`)
                                        }
                                        }
                                      />
                                    )}
                                  />
                                  {errors?.step3 && (errors?.step3 as any)?.generalAttributes && errors?.step3 && (errors?.step3 as any)?.generalAttributes[index]?.customAttributes?.type === "unique" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.generalAttributes[index]?.customAttributes?.message?.toString()}</span>}
                                </>
                              }
                            </div>

                            <div className="app-config-attributes_data">
                              <Controller
                                name={`step3.generalAttributes[${index}].attributeType`}
                                control={control}
                                render={({ field }) => (
                                  <Form.Select
                                    value={field.value}
                                    disabled={!getValues(`step3.generalAttributes[${index}].isCustom`)}
                                    className={errors?.step3 && (errors.step3 as any).generalAttributes && (errors.step3 as any).generalAttributes[index]?.attributeType ? "form-field-error  layer-top-drag" : "layer-top-drag"}
                                    onChange={(e) => field.onChange(e.target.value)}
                                  >
                                    {arrTypes.map((type, s) => (
                                      <option key={s} value={type.value}>{type.label}</option>
                                    ))}
                                  </Form.Select>
                                )}
                              />
                            </div>
                            <div className="app-config-attributes_data">
                              <Controller
                                name={`step3.generalAttributes[${index}].uiLabel`}
                                control={control}
                                render={({ field }) => (
                                  <Form.Control
                                    value={field.value}
                                    className={errors?.step3 && (errors.step3 as any).generalAttributes && (errors.step3 as any).generalAttributes[index]?.uiLabel ? "form-field-error" : ""}
                                    onChange={(e) => {
                                      field.onChange(e.target.value)
                                      trigger(`step3.generalAttributes`)
                                    }
                                    }
                                  />
                                )}
                              />
                              {errors?.step3 && (errors?.step3 as any)?.generalAttributes && errors?.step3 && (errors?.step3 as any)?.generalAttributes[index]?.uiLabel?.type === "unique" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.generalAttributes[index]?.uiLabel?.message?.toString()}</span>}
                            </div>
                            <div className="app-config-attributes_data fixed-column-size-small">
                              <Button disabled={fieldsGeneral.length === 1} title="Click to Remove" variant="danger" className="btn btn-sm" onClick={removeAttributesOfGeneral(index)}><Icon name="delete"></Icon></Button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>}
        </div>

        {ClientBillingAttribute.status === "loading"
          || generalBillingAttributesLoader === "loading" || paymetBillingAttributesLoader === "loading" ? <div className="d-flex w-100 justify-content-center">
          <Skeleton className="me-2" count={1} width={180} height={30} />
          {/* <Skeleton count={1} width={180} height={30} /> */}
        </div> : <div className="text-center px-2">
          <Button onClick={() => { addNewAttributesForGeneral("add"); updateGeneralBillingAttribute(); }} variant="secondary" className="mx-2">Pre-Defined Attributes +</Button>
          {/* <Button onClick={() => { addNewAttributesForGeneral("custom"); updateGeneralBillingAttribute(); }} variant="outline-secondary" className="mx-2">Custom Attributes +</Button> */}
        </div>}
      </div>

      <div className="w-100 mt-5" >
        <h2 className="h4">
          Configure Attributes for Payment Information
        </h2>
        <div className="app-config-attributess">
          <div className="app-config-attributes_row row-header">
            <div className="app-config-attributes_header fixed-column-size-small">Sort</div>
            <div className="app-config-attributes_header">
              Billing Attributes
            </div>
            <div className="app-config-attributes_header">Type</div>
            <div className="app-config-attributes_header fixed-column-size">
              Is Editable?
            </div>
            <div className="app-config-attributes_header">
              UI Display Label
            </div>
            {/* Task 42171: Add Default column to Configure Billing Attributes Page */}
             <div className="app-config-attributes_header">
              Default
            </div> 
            <div className="app-config-attributes_header fixed-column-size-small">
              Delete
            </div>
          </div>
          {ClientBillingAttribute.status === "loading"
            || generalBillingAttributesLoader === "loading" || paymetBillingAttributesLoader === "loading" ?
            <ConfigureattributesPlaceholder /> :
            <DragDropContext onDragEnd={reorderPayment}>
              <Droppable droppableId="parentProfile">
                {(provided) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps} className="row-data-container">
                    {fieldsPayment.map((item, index) => (

                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="app-config-attributes_row app-config-item">
                            <div
                              className="app-config-attributes_data fixed-column-size-small drag-icon"
                              {...provided.dragHandleProps}><Icon name="drag"></Icon></div>
                            <div className="app-config-attributes_data">
                              {
                                !getValues(`step3.paymentAttributes[${index}].isCustom`) &&
                                <>
                                  <Controller
                                    name={`step3.paymentAttributes[${index}].billingAttributes`}
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Select options={billingPaymentAttributesOptions}
                                          className={errors?.step3 && (errors?.step3 as any)?.paymentAttributes && (errors?.step3 as any)?.paymentAttributes[index]?.billingAttributes ? "form-field-error" : ""}
                                          defaultValue={field.value}
                                          onChange={(billing: any) => {
                                            console.log(billing)
                                            field.onChange(billing);
                                            const selectedAttribute = generalBillingAttributes.find((billingAttr: any) => {
                                              return billingAttr.attributeName.value == billing.value
                                            })
                                            if (billing.value) {
                                              updatePaymentBillingAttribute();
                                              setValue(`step3.paymentAttributes[${index}].attributeType`, selectedAttribute?.attributeType)
                                              trigger(`step3.paymentAttributes`)
                                            }
                                          }}
                                        ></Select>
                                      </>
                                    )}
                                  />       
                                  {errors?.step3 && (errors?.step3 as any)?.paymentAttributes && errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.billingAttributes?.type === "unique" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.billingAttributes?.message?.toString()}</span>}
                                </>
                              }
                              {
                                getValues(`step3.paymentAttributes[${index}].isCustom`) &&
                                <>
                                  <Controller
                                    name={`step3.paymentAttributes[${index}].billingAttributes`}
                                    control={control}
                                    render={({ field }) => (
                                      <Form.Control
                                        value={field?.value?.label}
                                        className={errors?.step3 && (errors?.step3 as any)?.paymentAttributes && (errors?.step3 as any)?.paymentAttributes[index]?.billingAttributes ? "form-field-error" : ""}
                                        onChange={(e) => {
                                          let oldValue = field.value;
                                          oldValue.label = e.target.value;
                                          field.onChange(oldValue);
                                          setValue(`step3.paymentAttributes[${index}].customAttributes`, e.target.value);
                                          trigger(`step3.paymentAttributes`);
                                        }
                                        }
                                      />
                                    )}
                                  />
                                  {errors?.step3 && (errors?.step3 as any)?.paymentAttributes && errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.billingAttributes?.type === "unique" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.billingAttributes?.message?.toString()}</span>}
                                </>
                              }

                            </div>
                            <div className="app-config-attributes_data">
                              <Controller
                                name={`step3.paymentAttributes[${index}].attributeType`}
                                control={control}
                                render={({ field }) => (
                                  <Form.Select
                                    value={field.value}
                                    // disabled={getValues(`step3.paymentAttributes[${index}].isCustom`)}
                                    disabled
                                    className={errors.step3 && (errors.step3 as any).paymentAttributes && (errors.step3 as any).paymentAttributes[index]?.attributeType ? "form-field-error" : ""}
                                    onChange={(e) => field.onChange(e.target.value)}
                                  >
                                    {arrTypes.map((type, s) => (
                                      <option key={s} value={type.value}>{type.label}</option>
                                    ))}
                                  </Form.Select>
                                )}
                              />
                            </div>
                            {<div className="app-config-attributes_data fixed-column-size">
                            {
                              getValues(`step3.paymentAttributes[${index}].isCustom`) ?

                                <Controller
                                  name={`step3.paymentAttributes[${index}].isEditable`}
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Form.Check
                                        value={field.value}
                                        checked={field.value}
                                        disabled={!getValues(`step3.paymentAttributes[${index}].isCustom`)}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        label={!field.value ? "No" : "Yes"}
                                      />
                                    </>
                                  )}
                                />
                                :
                                "No"
                            }
                          </div>}
                           {/* <div className="app-config-attributes_data fixed-column-size">
                              {getValues(`step3.paymentAttributes[${index}].isCustom`) ? "Yes" : "No"}
                            </div>
                           */}
                            <div className="app-config-attributes_data">
                              <Controller
                                name={`step3.paymentAttributes[${index}].uiLabel`}
                                control={control}
                                render={({ field }) => (
                                  <Form.Control
                                    value={field.value}
                                    className={errors.step3 && (errors.step3 as any).paymentAttributes && (errors.step3 as any).paymentAttributes[index]?.uiLabel ? "form-field-error" : ""}
                                    onChange={(e) => {
                                      field.onChange(e.target.value)
                                      trigger(`step3.paymentAttributes`)
                                    }
                                    }
                                  />
                                )}
                              />
                              {errors?.step3 && (errors?.step3 as any)?.paymentAttributes && errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.uiLabel?.type === "unique" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.uiLabel?.message?.toString()}</span>}
                            </div>
                            {/* Task 42171: Add Default column to Configure Billing Attributes Page */}
                            <div className="app-config-attributes_data">
                              {getValues(`step3.paymentAttributes[${index}].isCustom`) ? 
                                <React.Fragment>
                                  <Controller
                                    // name={`step3.paymentAttributes[${index}].customDefaults`}
                                    name={`step3.paymentAttributes[${index}].defaultValue`}
                                    control={control}
                                    render={({ field }) => (
                                      <Form.Control
                                        value={ field.value || ''}
                                        // className={errors.step3 && (errors.step3 as any).paymentAttributes && (errors.step3 as any).paymentAttributes[index]?.customDefaults ? "form-field-error" : ""}
                                        className={errors.step3 && (errors.step3 as any).paymentAttributes && (errors.step3 as any).paymentAttributes[index]?.defaultValue ? "form-field-error" : ""}
                                        onChange={(e) => {
                                          field.onChange(allowOneDot(e.target.value))
                                          trigger(`step3.paymentAttributes`)
                                        }
                                        }
                                      />
                                    )}
                                  />
                                  {/* {errors?.step3 && (errors?.step3 as any)?.paymentAttributes && errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.customDefaults === "required" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.customDefaults?.message?.toString()}</span>} */}
                                  {errors?.step3 && (errors?.step3 as any)?.paymentAttributes && errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.defaultValue === "required" && <span className="form-field-error-msg">{errors?.step3 && (errors?.step3 as any)?.paymentAttributes[index]?.defaultValue?.message?.toString()}</span>}
                                </React.Fragment>
                                : "N/A"
                              }
                            </div>
                            <div className="app-config-attributes_data fixed-column-size-small">
                              <Button disabled={fieldsPayment.length === 1} title="Click to Remove" variant="danger" className="btn btn-sm" onClick={removeAttributesOfPayment(index)}><Icon name="delete"></Icon></Button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>}
        </div>
        {ClientBillingAttribute.status === "loading"
            || generalBillingAttributesLoader === "loading" || paymetBillingAttributesLoader === "loading" ? <div className="d-flex w-100 justify-content-center">
               <Skeleton className="me-2" count={1} width={180} height={30} />
               <Skeleton count={1} width={180} height={30} />
            </div>:
        <div className="text-center px-2">
          <Button onClick={() => { addNewAttributesForPayment("add"); updatePaymentBillingAttribute(); }} variant="secondary" className="mx-2">Pre-Defined Attributes +</Button>
          <Button onClick={() => { addNewAttributesForPayment("custom"); updatePaymentBillingAttribute(); }} variant="outline-secondary" className="mx-2">Additional Amount Attributes +</Button>
        </div>}
      </div>
      <ToastNotification
        autohide
        show={showSucess}
        delay={5000}
        icon="check-double"
        type="success"
        title="Success!!!"
        position="top-center"
        body="Billing attributes have been saved successfully."
        onClose={() => setShowSuccess(false)}
      />
      <ToastNotification
        autohide
        show={showFailed}
        delay={5000}
        icon="close"
        type="danger"
        title={"Failed!!!"}
        position="top-center"
        body={"Sorry for the inconvenience caused by internal server error. Please try again later."}
        onClose={() => setShowFailed(false)}
      />
    </>
  );
};

export default ConfigureAttributes;
