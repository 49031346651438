//@ts-nocheck
// Hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// Api
import { getUserInfo } from "../../api/user";
import { getClientLogo } from "../../api/client";
import { getUserAccounts } from "../../api/userAccount";

// Components
import Icon from "../../components/Common/Icons/Icons";

import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

// Util
import { v4 as uuidv4 } from "uuid";
import { ROLE, UsersInGroup } from "../../routes/Roles";
import { $toast, toastMessage } from "../../util/toastUtil";

// Store
import { setPermissions } from "../../features/permissions/permissionsSlice";
import {
  initUserDetails,
  setRole,
  setUser,
  setUserAccounts,
  setClientLogo, setAccountId, setAccountNumber
} from "../../features/account/accountSlice";
import {
  accountType,
  clientAccount,
  configureLanguage,
  typeFace,
  getBrandColors
} from "../../api/clientAccount";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import { shallowEqual } from "react-redux";

function Welcome() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showAuthError, setShowAuthError] = useState(false);
  const [showAccountError, setShowAccountError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { isAuthenticated } = useAppSelector(
    (state: any) => ({ ...state.account }),
    shallowEqual
  );

  console.log("isAuthenticated: ", isAuthenticated);

  const initiateUserAccess = () => {
    $toast.clearToasts();

    if (isAuthenticated) {
      setLoading(true);
      setShowAuthError(false);
      if (isAuthenticated) {

        getUserInfo()
          .then((userRes) => {
            if (userRes.status === 200) {
              const { role, userDetails, isActive, isClientActive, clientId } =
                userRes.data;
              localStorage.setItem('userDetails', JSON.stringify(userRes.data));
              if (!isActive || !isClientActive) {
                setLoading(false);
                setShowAuthError(true);
                return;
              }
              // disabled updating the lastlogin from client side
              // updateLoginDate(userRes.data.userId);
              dispatch(setUser(userRes.data));
              dispatch(setRole(role));
              let permissions = {
                canViewProfile: role.roleName === ROLE.Consumer,
                canShowBreadcrumb: role.roleName === ROLE.ClientAdmin,
                canShowUsers: [
                  ROLE.VentureAdmin,
                  ROLE.ClientSuperAdmin
                ].includes(role.roleName),
                canModifyDeleteClientPaymentMethods: [
                  ROLE.ClientAdmin,
                  ROLE.ClientSuperAdmin
                ].includes(role.roleName),
                canViewPayment: [
                  ROLE.ClientAdmin,
                  ROLE.ClientSuperAdmin
                ].includes(role.roleName),
                canViewDocuments: [
                  ROLE.ClientAdmin,
                  ROLE.ClientSuperAdmin,
                  ROLE.ClientBasicAdmin
                ].includes(role.roleName)
              };
              dispatch(setPermissions(permissions));
              if (userDetails) {
                dispatch(initUserDetails(JSON.parse(userDetails)));
              }

              if (role && role.roleGroup) {
                console.log("current role: ", role);

                dispatch(setUser(userRes.data));
                dispatch(setRole(role));
                let permissions = {
                  canViewProfile: role.roleName === ROLE.Consumer,
                  canShowBreadcrumb: [
                    ROLE.ClientAdmin,
                    ROLE.ClientSuperAdmin,
                    ROLE.ClientBasic
                  ].includes(role.roleName),
                  canShowUsers: [
                    ROLE.VentureAdmin,
                    ROLE.ClientSuperAdmin
                  ].includes(role.roleName),
                  canModifyDeleteClientPaymentMethods: [
                    ROLE.ClientAdmin,
                    ROLE.ClientSuperAdmin
                  ].includes(role.roleName),
                  canViewPayment: [
                    ROLE.ClientAdmin,
                    ROLE.ClientSuperAdmin
                  ].includes(role.roleName),
                  canViewDocuments: [
                    ROLE.ClientAdmin,
                    ROLE.ClientSuperAdmin,
                    ROLE.ClientBasicAdmin
                  ].includes(role.roleName)
                };
                dispatch(setPermissions(permissions));
                if (userDetails) {
                  dispatch(initUserDetails(JSON.parse(userDetails)));
                }

                dispatch(setClientLogo(''));
                if (clientId && clientId.length > 0 && clientId != '00000000-0000-0000-0000-000000000000') {
                  getClientLogo(clientId).then((res) => {
                    if (res.status === 200) {
                      dispatch(setClientLogo(res.data));
                    }
                  })
                    .catch((resErr) => {
                      console.error("clientLogoErr: ", resErr);
                    });
                }
                switch (role.roleGroup.usersInGroup) {
                  case UsersInGroup.Consumer:
                    getUserAccounts()
                      .then(async (accountRes) => {
                        if (accountRes.status === 200) {
                          const userAccounts = Array.isArray(
                            accountRes.data
                          )
                            ? accountRes.data
                            : [];

                          setLoading(false);
                          dispatch(setUserAccounts(userAccounts));

                          if (userAccounts.length === 0) {
                            setShowAccountError(true);
                          } else if (userAccounts.length === 1) {
                            const { accountId, accountNumber } =
                              userAccounts[0];
                            dispatch(setAccountId(accountId));
                            dispatch(setAccountNumber(accountNumber));
                            navigate(`/consumer/account-detail`);
                          } else if (userAccounts.length > 1) {
                            navigate(`/consumer/accounts`);
                          }
                        }
                      })
                      .catch((accountErr) => {
                        console.error("accountErr: ", accountErr);
                        setLoading(false);
                      });
                    break;
                  case UsersInGroup.Client:
                    navigate(`/client`);
                    break;
                  case UsersInGroup.Venture:
                    dispatch(clientAccount());
                    dispatch(accountType());
                    dispatch(configureLanguage());
                    dispatch(typeFace());
                    dispatch(getBrandColors());
                    navigate(`/admin`);
                    break;
                  default:
                    $toast.danger({
                      id: uuidv4(),
                      show: true,
                      body: toastMessage.roleError,
                      icon: "warning",
                      autohide: true,
                      delay: 3 * 60 * 1000 // 3 minutes
                    });

                    setLoading(false);
                }
              } else {
                $toast.danger({
                  id: uuidv4(),
                  show: true,
                  body: toastMessage.roleError,
                  icon: "warning",
                  autohide: true,
                  delay: 3 * 60 * 1000 // 3 minutes
                });

                setLoading(false);
              }
            }
          })
          .catch((userErr) => {
            console.error("getUserInfo error: ", userErr);
            if (userErr.code) {
              $toast.danger({
                id: uuidv4(),
                show: true,
                body: toastMessage.serverError,
                icon: "warning",
                autohide: true,
                delay: 3 * 60 * 1000 // 3 minutes
              });
            }
            setLoading(false);
          });
      } else {
        setLoading(false);
        setShowAuthError(true);
      }


    } else {
      setLoading(false);
      setShowAuthError(true);
    }
  }; 

  useEffect(() => {
    initiateUserAccess();
  }, [isAuthenticated]);

  useEffect(() => {
    setLoading(true);
    setShowAuthError(false);
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!isAuthenticated) {
  //       navigate('/unauthorized')
  //     }
  //   }, 8000);
  //   return () => clearTimeout(timer);
  // }, [isAuthenticated]);

  return (
    <>
      <main>
        <LoadingWaitModal show={loading} size="lg" hide={() => { }} />
        {!showAuthError && (
          <Container>
            {/* <h1 className="2">Welcome!</h1>
            <Card className="app-card shadows-light mb-3 maxw-768 mx-auto">
              <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                  <Icon name="account-circle" />
                  Account Selection
                </Card.Title>
                <Row>
                  <Col md={12}>
                    <p className="mb-1">
                      <strong>Member Number</strong>
                    </p>
                    <p>{user?.email}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card> */}
          </Container>
        )}
        {!showAuthError && !loading && showAccountError && (
          <Container>
            <Row>
              <Col md={12} className="text-center">
                <strong>
                  There is not available payment account for you, please contact your organization.
                </strong>
              </Col>
            </Row>
          </Container>
        )}
        {!showAuthError && loading && (
          <Container>
            {/* <Row>
              <Col md={12} className="text-center">
                Processing your request. Please wait!
              </Col>
            </Row> */}
          </Container>
        )}
        {showAuthError && !loading && (
          <div className="startup-outer">
            <Card className="startup-inner">
              <Card.Body className="p-0">
                <Card.Text className="text-center">
                  You are not authorized to access this page!
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        )}
      </main>
    </>
  );
}

export default Welcome;
