import React, { FC, useEffect, useMemo, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFilterForm } from "../../hooks/useFilterForm";

// Components
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { DatePicker } from "../../components/Common/DatePicker";
import {
  FromToDateRangeFilter,
  Table,
  TableFilter,
  TableHeader,
} from "../../components/Common/Table";
import breakPoints from "../../scss/abstracts/_variables.scss";
import { LoadingWaitModal } from "../../components/Common/Modal";

// Utils
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { $toast, toastMessage } from "../../util/toastUtil";
import { alphaCharactersWithSpaceRegExFun } from "../../util/regexUtil"
import { getPdfMethod } from "../../common/getPdfMethod";

import { IDocsSearchFormType } from "../../../types/custom-types";

// Api
import { getPdfListForClient } from "../../api/document";
import { formatDate } from "../../util/formatUtil";
import { replaceSpace } from "../../util/regexUtil";
import { isArray } from "lodash";

const ClientDocuments: FC = () => {

  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .test(
        "match",
        "Client Name is invalid",
        (val) => {
          if (!val) {
            return true
          }
          return alphaCharactersWithSpaceRegExFun().test(val)
        })
      .test(
        "len",
        "Can be empty or with string at least 1 characters and not more than 50",
        (val) => {
          if (!val) {
            return true;
          }
          return val.length >= 0 && val.length <= 50;
        }
      ),
    accountNumber: Yup.string().required("Account Number is a required field")
      .test(
        "len",
        "At least 1 characters and not more than 50",
        (val) => {
          if (!val) {
            return false;
          }
          return val.length > 0 && val.length <= 50;
        }
      )
  });

  const minDate = moment().subtract(6, "months").toDate();
  const maxDate = moment().toDate();

  const [startDate, setStartDate] = useState<Date | undefined>(minDate);
  const [endDate, setEndDate] = useState<Date | undefined>(maxDate);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);

  let clientDocumentSessionData = JSON.parse(`${sessionStorage.getItem("client document")}`) ? JSON.parse(`${sessionStorage.getItem("client document")}`) : [];
	let clientDocumentFilterData: any = {};
	if (isArray(clientDocumentSessionData)) {
		clientDocumentSessionData?.forEach((item: any) => {
			clientDocumentFilterData[item.id] = item.value;
		});
	}

  const initialDocsSearchValues: IDocsSearchFormType = {
    clientName: clientDocumentFilterData?.clientName ? clientDocumentFilterData.clientName : "",
    accountNumber: clientDocumentFilterData?.accountNumber ? clientDocumentFilterData.accountNumber : "",
  }



  const { control, handleSubmit, reset, getValues, formState: { errors } } = useForm<IDocsSearchFormType>(
    {
      resolver: yupResolver(validationSchema),
      defaultValues: initialDocsSearchValues
    }
  );

  const initialTableSearch = {
    statementDate: {
      from: clientDocumentFilterData?.statementDate?.from ? moment(clientDocumentFilterData.statementDate.from).toDate() : minDate,
      to: clientDocumentFilterData?.statementDate?.to ? moment(clientDocumentFilterData.statementDate.to).toDate() : maxDate
    },
    clientName: clientDocumentFilterData?.clientName ? clientDocumentFilterData.clientName : "",
    accountNumber: clientDocumentFilterData?.accountNumber ? clientDocumentFilterData.accountNumber : "",
    documentType: clientDocumentFilterData?.documentType ? clientDocumentFilterData.documentType : ""
  };
  
  const { filters, filterControl, registerFilter, getValuesFilter, resetFilterForm } =
    useFilterForm({
      defaultValues: initialTableSearch
    });

  useEffect(() => {
    setStartDate(clientDocumentFilterData?.statementDate?.from ? moment(clientDocumentFilterData.statementDate.from).toDate() : minDate);
    setEndDate(clientDocumentFilterData?.statementDate?.to ? moment(clientDocumentFilterData.statementDate.to).toDate() : maxDate);
  }, [])

  useEffect(() => {
    sessionStorage.setItem('client document', JSON.stringify(filters));
  }, [filters])

  const filterTypes = useMemo(
    () => ({
      fromToDateRangeFilter: FromToDateRangeFilter,
    }),
    []
  );

  const columns =
    [
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Customer Name</TableHeader>
        ),
        accessor: "clientName",
        filter: "clientNameFilter",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Account Number</TableHeader>
        ),
        accessor: "accountNumber",
        filter: "accountNumberFilter",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Document Date</TableHeader>
        ),
        accessor: "statementDate",
        filter: "fromToDateRangeFilter",
        sortType: (rowA: any, rowB: any, columnId: string) => {
          const _rowA = moment(rowA.values.paymentDateRaw)
          const _rowB = moment(rowB.values.paymentDateRaw)
          return moment(_rowA).isAfter(moment(_rowB)) ? 1 : -1
        },
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Document Type</TableHeader>
        ),
        accessor: "documentType",
        filter: "documentTypeFilter",
      },
      {
        Header: () => (
          <div>View Document</div>
        ),
        id: "viewDocuments",
        Cell: (props: any) => (
          <Button
            variant="outline-primary"
            onClick={() => {
              viewClick(props);
            }}
          >
            View
          </Button>
        )
      },
    ];

  const tablet = useMemo(
    () => Number(breakPoints.tablet.replace("px", "")),
    []
  );

  const onSearch = (searchData: IDocsSearchFormType) => {
    setIsLoading(true);
    !isSearched && setIsSearched(true);

    let docList = new Array<any>();
    getPdfListForClient(searchData).then(response => {
      if (typeof response.data === "object") {

        let docs = JSON.parse(response.data.docList);
        if (typeof docs === "string") {
          docs = JSON.parse(docs);
        }

        if (docs.mailItems && Array.isArray(docs.mailItems)) {
            docList = docs.mailItems.map((item: any) => {
            return {
              clientName: response.data.clientName,
              accountNumber: response.data.accountNumber,
              statementDate: formatDate(item.statementDate),
              documentType: item.mailDescription,
              documentId: item.productNumber,
              acctDtlId: item.mailLink.acctDtlId,
              parentNumber: item.mailLink.parentNumber,
              documentDate: formatDate(item.mailDate),
            }
          })
        }
      }
    }).catch(err => {
      $toast.danger({
        show: true,
        title: 'Failed',
        body: toastMessage.notFoundDocumentList,
        icon: "warning",
        autohide: true,
        delay: 3000
      })
    }).finally(() => {
      setTableData(docList);
      setIsLoading(false);
    })
  }

  const resetTableSearch = () => {
    const initialTableSearchData = {
      statementDate: {
        from: minDate,
        to: maxDate
      },
      clientName: "",
      accountNumber: "",
      documentType: ""
    };
    sessionStorage.setItem('client document', JSON.stringify(initialTableSearchData));
    resetFilterForm(initialTableSearchData);
    filterFieldsChange()
  }

  const viewClick = async (cell: any) => {
    const currentRow = cell?.row?.original;
    const acctDtlId = currentRow?.acctDtlId;

    if (acctDtlId) {
      setIsLoading(true);
      await getPdfMethod(acctDtlId, () => {
        setIsLoading(false);
      });
    }
  };

  const tableRef = useRef<any>()

  const filterFieldsChange = ()=>{
    tableRef?.current?.gotoFirstPage()
  }

  let filteredData = tableData.filter(item => {
    const itemDate = moment(moment(item.statementDate).format('YYYY-MM-DD'));
    return itemDate.isBetween(startDate, endDate, null, '[]');
  });
  filteredData = filteredData.sort((a,b):any=> moment(a.statementDate).isAfter(moment(b.statementDate)) ? -1 : 1);
  
  return (
    <main id="mainContent">
      <LoadingWaitModal show={isLoading} size={"lg"}></LoadingWaitModal>
      <Container>
        <h1 className="h2">Customer Documents</h1>

        <Card className="app-card maxw-480 maxw-until-md mb-3">
          <Card.Body>
            <Form>
              <Row>
              {/*Task 43274: Disable the Client Name as a Search Option on Statements Landing Page (previously known as Documents page) */}
                {/*<Col sm={6}>*/}
                {/*  <Form.Group className="mb-3">*/}
                {/*    <Form.Label*/}
                {/*      htmlFor="docClientName"*/}
                {/*    >*/}
                {/*      Client Name*/}
                {/*    </Form.Label>*/}
                {/*    <Controller*/}
                {/*      name="clientName"*/}
                {/*      control={control}*/}
                {/*      render={({ field }) => (*/}
                {/*        <Form.Control*/}
                {/*          type="text"*/}
                {/*          id="docClientName"*/}
                {/*          value={field.value}*/}
                {/*          maxLength={50}*/}
                {/*          className={(errors?.clientName) ? "form-field-error" : ""}*/}
                {/*          placeholder="First Name Last Name"*/}
                {/*          onChange={(e) => field.onChange(replaceSpace((e.target.value.replace(/[\@\(\)]/,'')),false))}*/}
                {/*        />*/}
                {/*      )}*/}
                {/*    />*/}
                {/*    {errors?.clientName && <span className="form-field-error-msg">{errors.clientName.message?.toString()}</span>}*/}
                {/*  </Form.Group>*/}
                {/*</Col>*/}
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="docAccountNumber"
                    >
                      Account Number
                    </Form.Label>
                    <Controller
                      name="accountNumber"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          id="docAccountNumber"
                          value={field.value}
                          maxLength={50}
                          className={(errors?.accountNumber) ? "form-field-error" : ""}
                          placeholder="xxxxxxxx"
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      )}
                    />
                    {errors?.accountNumber && <span className="form-field-error-msg">{errors.accountNumber.message?.toString()}</span>}
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-grid gap-3 d-md-flex">
                <Button type="reset" variant="outline-primary"
                  onClick={() => {
                    reset(initialDocsSearchValues)
                    resetTableSearch()
                    setIsSearched(false)
                    setStartDate(minDate)
                    setEndDate(maxDate)
                  }}
                >
                  Clear
                </Button>
                {/* For Demo purpose, change to type submit for real app */}
                <Button variant="primary"
                  onClick={
                    handleSubmit(
                      (data) => {
                        data.clientName = clientDocumentFilterData?.clientName ? clientDocumentFilterData.clientName : "",
                        console.log('search data: ', data)
                        onSearch(data);
                      },
                      (err) => {
                        console.error('search error: ', err)
                      }
                    )
                  }
                >
                  Search
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>

        {isSearched &&
          <div id="adminOverviewTableFilters" className="app-panel-container">
            <div className="app-left-panel">
              <TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
                <Accordion
                  className="app-accordions" defaultActiveKey={['0','1','2','3']} alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Customer Name</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientName",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Account Number</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("accountNumber",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Document Date</Accordion.Header>
                    <Accordion.Body>
                      <Controller
                        name="statementDate.from"
                        control={filterControl}
                        render={({ field }) => (
                          <div className="text-center">
                            <DatePicker
                              containerClassName="mb-1"
                              selectedDate={field.value}
                              placeholderText="MM/DD/YYYY"
                              maxDate={endDate ? endDate : maxDate}
                              onChange={(date: Date | undefined) => {
                                if (date) {
                                  field.onChange(date);
                                } else {
                                  field.onChange("");
                                }
                                setStartDate(date);
                                setEndDate(getValuesFilter("statementDate.to"));
                                filterFieldsChange()
                              }}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="statementDate.to"
                        control={filterControl}
                        render={({ field }) => (
                          <div className="text-center">
                            <DatePicker
                              selectedDate={field.value}
                              placeholderText="MM/DD/YYYY"
                              minDate={startDate}
                              maxDate={maxDate}
                              onChange={(date: any) => {
                                field.onChange(date);
                                setStartDate(getValuesFilter("statementDate.from"));
                                setEndDate(date);
                                filterFieldsChange()
                              }}
                            />
                          </div>
                        )}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Document Type</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("documentType",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </TableFilter>
            </div>
            <div className="app-right-panel">
              <Table
                ref={tableRef}
                columns={columns}
                data={filteredData}
                filters={filters}
                filterTypes={filterTypes}
                sortable
                bordered
                hover
                responsive
                // @TODO: Testing functionality for admin component with expandable rows
                getSubRows={undefined}
                subComponent={undefined}
              />
            </div>
          </div>
        }
      </Container>
    </main>
  );
}

export default ClientDocuments;
