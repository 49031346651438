import * as Yup from "yup";

import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getClientAccountInfo, saveClientAccountInfo } from "../../api/clientAccount";
import { saveBillingAttributes, saveClientNotification, savePaymentMethods, setClientAccountStatus } from "../../api/configureClient";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import ActionButtonPlaceholder from "./placeholders/actionButtonPlaceholder";
import ConfigureAccount from "./ConfigureAccount";
import ConfigureAttributes from "./ConfigureAttributes";
import ConfigureMethods from "./ConfigureMethods";
import ConfigureNotifications from "./ConfigureNotifications";
import Icon from "../../components/Common/Icons/Icons";
import { LoadingWaitModal } from "../../components/Common/Modal";
import NavigationPrompt from '../../components/NavigationPrompt/NavigationPrompt';
import { StubbedNetworkModule } from "@azure/msal-common";
import { ToastNotification } from "../../components/Common/Toast";
import { shallowEqual } from "react-redux";
import { useCallbackPrompt } from '../../components/NavigationPrompt/hooks/useCallbackPrompt';
import { yupResolver } from "@hookform/resolvers/yup";

import { guidRegex } from "../../util/regexUtil";

function ConfigureClient() {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState("step1");

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const ignoredUrls = ["/admin/configure-client-summary", "/admin/configure-client/"];
  const [showPrompt, confirmNavigation, cancelNavigation ] = useCallbackPrompt(showDialog, ignoredUrls)

  const [loading, setLoading] = useState(false);
  const [showSucess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  const getNotification = useAppSelector((state: any) => state.getClientNotification, shallowEqual);
  const ClientBillingAttribute = useAppSelector((state: any) => state.getClientBilling.status, shallowEqual)
  const generalBillingAttributesLoader = useAppSelector((state: any) => state.generalBillingAttributes.status, shallowEqual)
  const paymetBillingAttributesLoader = useAppSelector((state: any) => state.generalBillingAttributes.status, shallowEqual)
  const paymentMethodsLoader = useAppSelector((state: any) => state.paymentMethods.status, shallowEqual);
  const accountInfo = useAppSelector((state: any) => state.clientAccount.clientAccountInfo.status, shallowEqual);
  const clientNameLoad = useAppSelector((state: any) => state.clientAccount)


  type accountTypes = {
    id: string;
    status: string;
    step1: {
      companyName: { value: string, label: string };
      industry: { value: string, label: string };
      language: { value: string, label: string };
      // apiSecurityKey: string;
      primaryBrandColor: { value: string, brand: string };
      secondaryBrandColor: { value: string, brand: string };
      typeface: { value: string, label: string };
      clientLogo: string;
      landingPageURL: string;
      docViewCustNumber: string;
      // docViewInitVector: string;
      // docViewSecretKey: string;
      isConsumerProfileMessageEnabled: boolean;
      consumerProfileMessage: string;
      isConsumerHomeOneTimeMessageEnabled: boolean;
      consumerHomeOneTimeMessage: string;
      isConsumerMaskAccountEnabled: boolean;
      consumerMaskAccount: string;
      // isAchDisclaimerMessageEnabled: boolean;
      achDisclaimerMessage: string;
      // isCreditCardDisclaimerMessageEnabled: boolean;
      creditCardDisclaimerMessage: string;
    }
    step2: {
      clientId:string;
      dmClientID: string;
      dmFromAddress: string;
      dmFromName: string;
      getNotification: {
        notificationId: number | null;
        label: string;
        description: string;
        notificationSubscriptionItems : {
          subscriptionId: number | null;
          notificationTypeId: number;
          notificationTypeName: string;
          enabled: boolean;
          notificationTemplateId: string;
        }[]
      }[]
    },
    step3: {
      generalAttributes:{
        billingAttributes: { value: number | string, label: string };
        type: string;
        uiLabel: string;
        isCustom: boolean;
        customAttributes: string;
        labelSequenceNo: number | null;
      }[],
      paymentAttributes:{
        billingAttributes: { value: number | string, label: string };
        type: string;
        uiLabel: string;
        isCustom: boolean;
        isEditable: boolean;
        customAttributes: string;
        // Task 42171: Add Default column to Configure Billing Attributes Page
        //customDefaults: string;
        defaultValue: string;
        labelSequenceNo: number | null;
      }[]
    },
    step4: {
      enableAllMethods: boolean;
      enableBankAccountMethod: boolean;
      enableCreditCardMethod: boolean;
      enableDebitCardMethod: boolean;
      paymentMethods: {
        paymentMethodId: string;
        paymentMethodName: string;
        isActive: boolean;
      }[]
    }
  };

  const intialValues = {
    status: "Incomplete",
    step1: {
      companyName: { value: "", label: "Select..." },
      industry: { value: "", label: "Select..." },
      language: { value: "", label: "Select..." },
      // apiSecurityKey: "",
      primaryBrandColor: { label: "#378523", value: "32" },
      secondaryBrandColor: { label: "#255C96", value: "33" },
      typeface: { value: "", label: "Select..." },
      clientLogo: "",
      landingPageURL: "",
      docViewCustNumber: "",
      // docViewInitVector: "",
      // docViewSecretKey: "",
      isConsumerProfileMessageEnabled: false,
      isConsumerHomeOneTimeMessageEnabled: false,
      isConsumerMaskAccountEnabled: false,
      consumerProfileMessage: "",
      consumerHomeOneTimeMessage: "",
      consumerMaskAccount: "",
      // isAchDisclaimerMessageEnabled: false,
      achDisclaimerMessage: "By submitting this payment, I authorize [Company Name] to debit the bank account or charge the debit card indicated on this web form. I understand that in the event this transaction is returned by my financial institution, I may be subject to a returned item fee. I also understand that this authorization will remain in full force and effect until I notify [Company Name] at [Company Phone Number] or by updating the payment schedule via the online portal.  Changes to the authorized and/or scheduled payment(s) must be completed by 7:00 p.m. CST of the scheduled payment date for the change to be effective.",
      // isCreditCardDisclaimerMessageEnabled: false,
      creditCardDisclaimerMessage: "By submitting this payment, I authorize [Company Name] to charge the credit card indicated on this web form. I understand that this authorization will remain in full force and effect until I notify [Company Name] at [Company Phone Number] or by updating the payment schedule via the online portal.  Changes to the authorized and/or scheduled payment(s) must be completed by 7:00 p.m. CST of the scheduled payment date for the change to be effective."
    },
    step2: {
      clientId: '',
      dmClientID: '',
      dmFromAddress: '',
      dmFromName: '',
      getNotification: []
    },
    step3: {
      generalAttributes: [
        {
          billingAttributes: { value: 0, label: 'Select Attribute' },
          attributeType: "",
          uiLabel: '',
          isCustom: false,
          customAttributes: "",
          labelSequenceNo: null
        }
      ],
      paymentAttributes: [
        {
          billingAttributes: { value: 0, label: 'Select Attribute' },
          attributeType: "",
          uiLabel: '',
          isCustom: false,
          isEditable: false,
          customAttributes: "",
          // Task 42171: Add Default column to Configure Billing Attributes Page
          defaultValue: "",
          labelSequenceNo: null
        }
      ]
    },
    step4: {
      enableAllMethods: false,
      enableBankAccountMethod: false,
      enableCreditCardMethod: false,
      enableDebitCardMethod: false,
      paymentMethods: []
    }
  };

  const isConsumerProfileMessage = (value: any) => {
    if (getValues("step1.isConsumerProfileMessageEnabled") == true && !value)
      return false;
    else {
      return true;
    }
  };

  const isConsumerHomeOneTimeMessage = (value: any) => {
    if (getValues("step1.isConsumerHomeOneTimeMessageEnabled") == true && !value)
      return false;
    else {
      return true;
    }
  };

  const isLanguageRequired = (value: any) => {
    if (!value)
      return false;
    else
      return true
  };

  const isBillingRequired = (billing: any, allValues: any) => {
    if(allValues.parent.isCustom) {
        return true
    } else {
      if (!billing?.value)
        return false;
      else
        return true
    }
  }

  const isTypeRequired = (type: any, allValues: any) => {
    console.log(type);
    if(!allValues.parent.isCustom) {
        return true
    } else {
      if (!type)
        return false;
      else
        return true
    }
  }

  const isCustomBillingRequired = (billing: any, allValues: any) => {
    if(allValues.parent.isCustom) {
      if (!billing)
        return false;
      else
        return true
    } else {
      return true;
    }
  }

  const isBrandColorRequired = (color: any) => {
    if (color && !color.value)
      return false;
    else
      return true
  };

  // const isAchDisclaimerMessage = (value: any) => {
  //   if (getValues("step1.isAchDisclaimerMessageEnabled") == true && !value)
  //     return false;
  //   else {
  //     return true;
  //   }
  // };

  // const isCreditDisclaimerMessage = (value: any) => {
  //   if (getValues("step1.isAchDisclaimerMessageEnabled") == true && !value)
  //     return false;
  //   else {
  //     return true;
  //   }
  // };

  const isRequired = (data: any) => {
    if (!data || data.value == "")
      return false;
    else
      return true
  };

  const convertNotificationModel = () => {

    let notifications: any = [];

    getValues("step2.getNotification").forEach((item: any) => {
      item.notificationSubscriptionItems.forEach((subItem: any) => {
        if (subItem.notificationTemplateId.length == 0 || subItem.notificationTemplateId == '00000000-0000-0000-0000-000000000000') {
          subItem. notificationTemplateId = null;
        }
        notifications.push({ notificationTypeId: subItem.notificationTypeId, isEnabled: subItem.enabled, templateId: subItem. notificationTemplateId});
      })
    })
    return {
      clientId:getValues("step2.clientId"),
      dmClientId: getValues("step2.dmClientID"),
      fromAddress: getValues("step2.dmFromAddress"),
      fromName: getValues("step2.dmFromName"),
      clientNotificationMethodUpdateModels:notifications
    }
  };

  const hasUniqueBilling = (billingAttr: any, allValues: any, section: string) => {
    //TO-DO: Need to check why billing address is undefined after setting DB data to field
    if(billingAttr) {
      let selectedAttribute = "";
      if (!allValues.parent.isCustom) {
        selectedAttribute = billingAttr.label
      } else {
        selectedAttribute = allValues.parent.customAttributes;
      }
      console.log(selectedAttribute, "selectedAttribute")
      let generalAttributesArr = section === "general" ? getValues("step3.generalAttributes") : getValues("step3.paymentAttributes");

      let matchedAttributes = generalAttributesArr?.filter((general) => {
        if (general.isCustom)
          if(section !== "general") {
            if (selectedAttribute)
              return (general.billingAttributes.label === selectedAttribute && general.billingAttributes.value !== allValues.parent.value)
            else
              return (general.billingAttributes.label === billingAttr.label && general.billingAttributes.value !== billingAttr.value)
          } else {
            return (general.customAttributes === selectedAttribute)
          }
        else
          return (general.billingAttributes.label === selectedAttribute)
      })
      if (matchedAttributes?.length > 1)
        return false;
      else
        return true;
    }
    return true;
  }

  const hasUniqueUILabel = (uiLabel: any, section: string) => {
    let generalAttributesArr = section === "general" ? getValues("step3.generalAttributes") : getValues("step3.paymentAttributes");

    let matchedAttributes = generalAttributesArr?.filter((general) => {
        return (general.uiLabel === uiLabel)
    })
    if (matchedAttributes && matchedAttributes?.length > 1)
      return false;
    else
      return true;
  }

    // Return Payment Confirmed
    const isPaymentConfirm = (value: any, allValues: any) => {
      if (allValues.parent.enabled == true && (!value || value === '00000000-0000-0000-0000-000000000000')) {
        return false;
      }
      else {
        return true;
      }

    };

  // Task 42171: Add Default column to Configure Billing Attributes Page
  const isCustomDefaultsRequired = (value: any, allValues: any) => {
    if(allValues.parent.isCustom) {
      if (!value)
        return false;
      else
        return true
    } else {
      return true;
    }
  };

  const validationSchema = Yup.object().shape({
    step1: Yup.object()
      .when('step', {
        is: () => {
          return step === 'step1'
        },
        then: Yup.object({
          companyName: Yup.object().test("required", "This is required", (value) => isRequired(value)),
          industry: Yup.object().test("required", "This is required", (value) => isRequired(value)),
          language: Yup.object().test("required", "This is required", (value) => isRequired(value)),
          // apiSecurityKey: Yup.string().required("This is required"),
          primaryBrandColor: Yup.object().test("required", "This is required", (value) => isBrandColorRequired(value)),
          //secondaryBrandColor: Yup.object().test("required", "This is required", (value) => isBrandColorRequired(value)),
          //typeface: Yup.object().test("required", "This is required", (value) => isRequired(value)),
          landingPageURL: Yup.string().url("Enter valid URL").required("This is required"),
          docViewCustNumber: Yup.string().required("This is required"),
          // docViewInitVector: Yup.string().required("This is required"),
          // docViewSecretKey: Yup.string().required("This is required"),
          consumerProfileMessage: Yup.string().test("required", "This is required", (value) => isConsumerProfileMessage(value)),
          consumerHomeOneTimeMessage: Yup.string().test("required", "This is required", (value) => isConsumerHomeOneTimeMessage(value)),
          // achDisclaimerMessage: Yup.string().test("required", "This is required", (value) => isAchDisclaimerMessage(value)),
          // achDisclaimerMessage: Yup.string().required("This is required"),
          // creditCardDisclaimerMessage: Yup.string().test("required", "This is required", (value) => isCreditCardDisclaimerMessage(value)),
          // creditCardDisclaimerMessage: Yup.string().required("This is required")
        })
      }),

      step2: Yup.object()
      .when('step', {
        is: () => {
          return step === 'step2'
        },
        then: Yup.object({
          dmClientID: Yup.string()
            .test("required", "This is required", (value) => isRequired(value))
            .matches(guidRegex, "Must be a valid guid"),
          dmFromAddress: Yup.string()
            .test("required", "This is required", (value) => isRequired(value))
            .email('Must be a valid email'),
          dmFromName: Yup.string().test("required", "This is required", (value) => isRequired(value)),
          getNotification: Yup.array().of(
            Yup.object().shape({
              notificationSubscriptionItems: Yup.array().of(
                Yup.object().shape({
                  notificationTemplateId: Yup.string().test("required", "This is required", (value, allValues) => isPaymentConfirm(value, allValues)),
                }))
            })),
        })
      }),

      step4: Yup.object()
      .when('step', {
        is: () => step === 'step4',
        then: Yup.object({
          enableAllMethods: Yup.string().test("required", "Minimum one payment method is required", () => {
              const filterActive = getValues("step4.paymentMethods").filter((item) => {
                return item.isActive === true
              });
              if(filterActive.length === 0)
                return false;
              else
                return true;
          })
        })
      }),

      step3: Yup.object()
      .when('step', {
        is: () => step === 'step3',
        then: Yup.object({
          generalAttributes: Yup.array().of(
            Yup.object().shape({
              billingAttributes: Yup.object().test("required", "This is required", (value, allValues) => isBillingRequired(value, allValues)).test("unique", "This should not match with another one", (value, allValues) => hasUniqueBilling(value, allValues, "general")),
              customAttributes: Yup.string().test("required", "This is required", (value, allValues) => isCustomBillingRequired(value, allValues)).test("unique", "This should not match with another one", (value, allValues) => hasUniqueBilling(value, allValues, "general")),
              attributeType: Yup.string().test("required", "This is required", (value, allValues) => isTypeRequired(value, allValues)),
              uiLabel: Yup.string().test("required", "This is required", (value) => isLanguageRequired(value)).test("unique", "This should not match with another one", (value, allValues) => hasUniqueUILabel(value, "general"))
            })),
          paymentAttributes: Yup.array().of(
            Yup.object().shape({
              billingAttributes: Yup.object().test("required", "This is required", (value, allValues) => isBillingRequired(value, allValues)).test("unique", "This should not match with another one", (value, allValues) => hasUniqueBilling(value, allValues, "payment"))
              .shape({
                label: Yup.string().test("required", "This is required", (value, allValues) => isCustomBillingRequired(value, allValues)).test("unique", "This  should not match with another one", (value, allValues) => hasUniqueBilling(value, allValues, "payment")),
              }),
              attributeType: Yup.string().test("required", "This is required", (value, allValues) => isTypeRequired(value, allValues)),
              uiLabel: Yup.string().test("required", "This is required", (value) => isLanguageRequired(value)).test("unique", "This should not match with another one", (value, allValues) => hasUniqueUILabel(value, "payment")),
              // Task 42171: Add Default column to Configure Billing Attributes Page
              //customDefaults: Yup.string().test("required", "This is required", (value) => isCustomDefaultsRequired(value))
              defaultValue: Yup.string().nullable().test("required", "This is required", (value, allValues) => isCustomDefaultsRequired(value, allValues))
            }))
        })
      }),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setError,
    setValue,
    trigger,
    clearErrors,
    formState: { errors, isDirty, dirtyFields, isValid }
  } = useForm<accountTypes>({
    resolver: yupResolver(validationSchema),
    defaultValues: intialValues
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<accountTypes> = (data) => {
    setLoading(true);
    const clientId = getValues("step1.companyName.value");
    switch (step) {
      case 'step1':
        saveClientAccountInfo(data.step1).then(() => {
          setShowSuccess(true);
          setStep("step2");
        }).catch(() => {
          setShowFailed(true);
        }).finally(() => {
          setLoading(false);
        });
        break;
      case 'step2':
        saveClientNotification(convertNotificationModel(), clientId).then(() => {
          setShowSuccess(true);
          setStep("step3");
        }).catch(() => {
          setShowFailed(true);
        }).finally(() => {
          setLoading(false);
        });
        break;
      case 'step3':
        saveBillingAttributes(data.step3, clientId).then(() => {
          setShowSuccess(true);
          setStep("step4");
        }).catch(() => {
          setShowFailed(true);
        }).finally(() => {
          setLoading(false);
        });
        break;
      case 'step4':
        savePaymentMethods(data.step4.paymentMethods, clientId).then(() => {
          setClientAccountStatus(clientId).then(() => {
            dispatch(getClientAccountInfo(clientId))
            navigate('/admin/configure-client-summary', { replace: true })
        }).catch(() => {
            setShowFailed(true);
          }).finally(() => {
            setLoading(false);
          });
        }).catch(()=> {
          setShowFailed(true);
        }).finally(() => {
          //setLoading(false);
        });
        break;
      default:
        setLoading(false);
        return null
    }
  };

  const onError: SubmitErrorHandler<accountTypes> = (errors, e) => { };

  const  isAccountExpired = useAppSelector(
    (state: any) => state.account.expired,
    shallowEqual
      );

  useEffect(() => {
      setShowDialog(isAccountExpired ? false : isDirty)
  }, [isDirty,isAccountExpired]);

  return (
    <main id="mainContent">
      <Container>
        <NavigationPrompt
          // @ts-ignore
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Row>
          <Col md={6}>
            <h1 className="h2">Add New Client</h1>
          </Col>
          <Col md={6} className="text-end">
            <div className="d-inline-flex align-items-center">
              <p className="mb-0 mx-2"><strong>Status:</strong></p>
              <span className="text-warning">{getValues("status")}</span>
            </div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Tabs
            defaultActiveKey={step}
            activeKey={step}
            id="configureClientData"
            className="app-stepper shadows-light"
            onSelect={(activeKey) => {
              if (step === "step4" && activeKey) {
                setStep(activeKey);
              } else if (step === "step3" && (activeKey === "step1" || activeKey === "step2")) {
                setStep(activeKey);
              } else if (step === "step2" && (activeKey === "step1")) {
                setStep(activeKey);
              } else {
                setStep(step);
              }
            }}
          >
            <Tab eventKey="step1" title="Configure Account">
            {step === "step1" ?
              <ConfigureAccount control={control} errors={errors} setValue={setValue} getValues={getValues} trigger={trigger}></ConfigureAccount>  :  null }
            </Tab>
            <Tab eventKey="step2" title="Configure Notifications">
            {step === "step2" ?
              <ConfigureNotifications control={control} errors={errors} setValue={setValue} getValues={getValues} trigger={trigger} setStep={setStep}></ConfigureNotifications>
              :  null }
              </Tab>

            <Tab
              eventKey="step3"
              title="Configure Attributes"
            >
              {step === "step3" ?
              <ConfigureAttributes control={control} errors={errors} setValue={setValue} getValues={getValues} trigger={trigger} setStep={setStep}></ConfigureAttributes>
              :  null }
           </Tab>
            <Tab eventKey="step4" title="Configure Methods">
              {step === "step4" ?
              <ConfigureMethods control={control} errors={errors} setValue={setValue} getValues={getValues} trigger={trigger} setStep={setStep}></ConfigureMethods>
              :  null }
              </Tab>
          </Tabs>
          {getNotification.status === "loading" || ClientBillingAttribute === "loading" ||generalBillingAttributesLoader === "loading" ||
          paymetBillingAttributesLoader === "loading" || paymentMethodsLoader === "loading" || accountInfo === "loading" || clientNameLoad.accountType.status === "loading"
          || clientNameLoad.brandColor.status === "loading" || clientNameLoad.clientName.status === "loading" ||  clientNameLoad.languageList.status === "loading"  ||
          clientNameLoad.typeFace.status === "loading" ?
          <ActionButtonPlaceholder/>:
          <nav
            className="app-step-buttons text-end"
            aria-label="Bottom Stepper navigation"
          >
            <Button variant="outline-primary" className="mx-2" onClick={()=>{ navigate('/admin', { replace: true })}}>
              Back to Home
            </Button>

            {
            step != 'step4' &&
            <Button onSubmit={handleSubmit(onSubmit, onError)} variant="primary" type="submit" className="mx-2">
              <Icon name="bookmark" size={24}></Icon> Save &amp; Continue
            </Button>
            }

            <Button onSubmit={handleSubmit(onSubmit, onError)} variant="primary" type="submit" disabled={step != "step4"} className="mx-2 me-0">
              <Icon name="check-double" size={24}></Icon> Finish
            </Button>
          </nav>}
        </Form>
      </Container>
      {/* <ToastNotification
          autohide
          show={showSucess}
          delay={5000}
          icon="check-double"
          type="success"
          title="Success!!!"
          position="top-center"
          body="Payment Methods have been saved successfully."
          onClose={() => setShowSuccess(false)}
      /> */}
      <ToastNotification
          autohide
          show={showFailed}
          delay={5000}
          icon="close"
          type="danger"
          title={"Failed!!!"}
          position="top-center"
          body={"Sorry for the inconvenience caused by internal server error. Please try again later."}
          onClose={() => setShowFailed(false)}
      />
      <LoadingWaitModal show={loading} size="lg" hide={() => { }} />
    </main>
  );
}

export default ConfigureClient;
